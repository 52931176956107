import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";
import paypal from '../../Images/paypal.svg.png';
import visa from '../../Images/visa.png';
import mastercard from '../../Images/mastercard.png';

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <div className="logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 8 8"
              >
                <path
                  fill="currentColor"
                  d="M5.5 0A2.5 2.5 0 0 0 3 2.5c0 .16 0 .32.03.47L0 6v2h3V6h2V5l.03-.03c.15.03.31.03.47.03a2.5 2.5 0 0 0 0-5M6 1c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1"
                />
              </svg>
              <h1>MicrosKey</h1>
            </div>
            <p>
              At MicrosKey, we are committed to providing genuine and affordable
              Microsoft licenses for businesses and individuals. We understand
              that businesses and individuals rely on Microsoft software to
              power their productivity and innovation. That's why we are
              committed to offering genuine, affordable, and easily accessible
              Microsoft licenses to meet your specific needs.
            </p>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>About Us</h2>
            <ul>
              <li>
                <Link to="/shop" style={{ color: 'white' }}>Our Shop</Link>
              </li>
              <li>
                <Link to="/policy" style={{ color: 'white' }}>Privacy Policy</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Customer Service</h2>
            <ul>
              <li>
                <Link to="/faq" style={{ color: 'white' }}>Help</Link>
              </li>
              <li>
                <Link to="/refund" style={{ color: 'white' }}>Returns & Refunds</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Contact Us</h2>
            <ul>
              <li>123 Baker Street</li>
              <li>London</li>
              <li>W1U 6TY</li>
              <li>Email: <a href="mailto:microskey2024@gmail.com" style={{ color: 'white' }}>microskey2024@gmail.com</a></li>
            </ul>
          </Col>
          <div className="payment-icons" style={{ textAlign: 'center', marginTop: '10px' }}>
            <img src={paypal} alt="PayPal" style={{ marginLeft: '10px' }} />
            <img src={visa} alt="Visa" style={{ marginLeft: '10px' }} />
            <img src={mastercard} alt="MasterCard" style={{ marginLeft: '10px' }} />
          </div>

        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
